
// @ts-nocheck


export const localeCodes =  [
  "en",
  "es",
  "ca"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__home_pdl_htdocs_puigdellivol_com_locales_en_json" */), cache: true }],
  "es": [{ key: "../locales/es.json", load: () => import("../locales/es.json" /* webpackChunkName: "locale__home_pdl_htdocs_puigdellivol_com_locales_es_json" */), cache: true }],
  "ca": [{ key: "../locales/ca.json", load: () => import("../locales/ca.json" /* webpackChunkName: "locale__home_pdl_htdocs_puigdellivol_com_locales_ca_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "iso": "en-EN",
      "name": "ENG",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "name": "ESP",
      "files": [
        "locales/es.json"
      ]
    },
    {
      "code": "ca",
      "iso": "es-CA",
      "name": "CAT",
      "files": [
        "locales/ca.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "library": {
      "en": "/library",
      "ca": "/biblioteca",
      "es": "/biblioteca"
    },
    "mission": {
      "en": "/mission",
      "ca": "/missio",
      "es": "/mision"
    },
    "greenline": {
      "en": "/greenline",
      "ca": "/liniaverda",
      "es": "/lineaverde"
    },
    "contact": {
      "en": "/contact",
      "ca": "/contacte",
      "es": "/contacto"
    },
    "legal/legal-notice": {
      "en": "/legal/legal-notice",
      "ca": "/legal/avis-legal",
      "es": "/legal/aviso-legal"
    },
    "legal/privacy-policy": {
      "en": "/legal/privacy-policy",
      "ca": "/legal/politica-de-privacitat",
      "es": "/legal/politica-de-privacidad"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-EN",
    "name": "ENG",
    "files": [
      {
        "path": "locales/en.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es-ES",
    "name": "ESP",
    "files": [
      {
        "path": "locales/es.json"
      }
    ]
  },
  {
    "code": "ca",
    "iso": "es-CA",
    "name": "CAT",
    "files": [
      {
        "path": "locales/ca.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
