import { default as contactOO6d5L0BEGMeta } from "/home/pdl/htdocs/puigdellivol.com/pages/contact.vue?macro=true";
import { default as greenlineaheii9fsEaMeta } from "/home/pdl/htdocs/puigdellivol.com/pages/greenline.vue?macro=true";
import { default as indexiUXnZ5pY6kMeta } from "/home/pdl/htdocs/puigdellivol.com/pages/index.vue?macro=true";
import { default as legal_45noticewpnQ72bxJwMeta } from "/home/pdl/htdocs/puigdellivol.com/pages/legal/legal-notice.vue?macro=true";
import { default as privacy_45policyibBrU0Dlr2Meta } from "/home/pdl/htdocs/puigdellivol.com/pages/legal/privacy-policy.vue?macro=true";
import { default as legalc9RKhH9kWEMeta } from "/home/pdl/htdocs/puigdellivol.com/pages/legal.vue?macro=true";
import { default as librarynOTORHhtLFMeta } from "/home/pdl/htdocs/puigdellivol.com/pages/library.vue?macro=true";
import { default as missiongXqa2vWAPHMeta } from "/home/pdl/htdocs/puigdellivol.com/pages/mission.vue?macro=true";
import { default as our_45storyqlYjIRqEWyMeta } from "/home/pdl/htdocs/puigdellivol.com/pages/our-story.vue?macro=true";
import { default as wip5feJltVbL2Meta } from "/home/pdl/htdocs/puigdellivol.com/pages/wip.vue?macro=true";
import { default as puigdellivolo00hyJYhaiMeta } from "/home/pdl/htdocs/puigdellivol.com/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as puigdellivolo00hyJYhai } from "/home/pdl/htdocs/puigdellivol.com/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: contactOO6d5L0BEGMeta?.name ?? "contact___en",
    path: contactOO6d5L0BEGMeta?.path ?? "/contact",
    meta: contactOO6d5L0BEGMeta || {},
    alias: contactOO6d5L0BEGMeta?.alias || [],
    redirect: contactOO6d5L0BEGMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactOO6d5L0BEGMeta?.name ?? "contact___es",
    path: contactOO6d5L0BEGMeta?.path ?? "/es/contacto",
    meta: contactOO6d5L0BEGMeta || {},
    alias: contactOO6d5L0BEGMeta?.alias || [],
    redirect: contactOO6d5L0BEGMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactOO6d5L0BEGMeta?.name ?? "contact___ca",
    path: contactOO6d5L0BEGMeta?.path ?? "/ca/contacte",
    meta: contactOO6d5L0BEGMeta || {},
    alias: contactOO6d5L0BEGMeta?.alias || [],
    redirect: contactOO6d5L0BEGMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: greenlineaheii9fsEaMeta?.name ?? "greenline___en",
    path: greenlineaheii9fsEaMeta?.path ?? "/greenline",
    meta: greenlineaheii9fsEaMeta || {},
    alias: greenlineaheii9fsEaMeta?.alias || [],
    redirect: greenlineaheii9fsEaMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/greenline.vue").then(m => m.default || m)
  },
  {
    name: greenlineaheii9fsEaMeta?.name ?? "greenline___es",
    path: greenlineaheii9fsEaMeta?.path ?? "/es/lineaverde",
    meta: greenlineaheii9fsEaMeta || {},
    alias: greenlineaheii9fsEaMeta?.alias || [],
    redirect: greenlineaheii9fsEaMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/greenline.vue").then(m => m.default || m)
  },
  {
    name: greenlineaheii9fsEaMeta?.name ?? "greenline___ca",
    path: greenlineaheii9fsEaMeta?.path ?? "/ca/liniaverda",
    meta: greenlineaheii9fsEaMeta || {},
    alias: greenlineaheii9fsEaMeta?.alias || [],
    redirect: greenlineaheii9fsEaMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/greenline.vue").then(m => m.default || m)
  },
  {
    name: indexiUXnZ5pY6kMeta?.name ?? "index___en",
    path: indexiUXnZ5pY6kMeta?.path ?? "/",
    meta: indexiUXnZ5pY6kMeta || {},
    alias: indexiUXnZ5pY6kMeta?.alias || [],
    redirect: indexiUXnZ5pY6kMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexiUXnZ5pY6kMeta?.name ?? "index___es",
    path: indexiUXnZ5pY6kMeta?.path ?? "/es",
    meta: indexiUXnZ5pY6kMeta || {},
    alias: indexiUXnZ5pY6kMeta?.alias || [],
    redirect: indexiUXnZ5pY6kMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexiUXnZ5pY6kMeta?.name ?? "index___ca",
    path: indexiUXnZ5pY6kMeta?.path ?? "/ca",
    meta: indexiUXnZ5pY6kMeta || {},
    alias: indexiUXnZ5pY6kMeta?.alias || [],
    redirect: indexiUXnZ5pY6kMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalc9RKhH9kWEMeta?.name ?? "legal___en",
    path: legalc9RKhH9kWEMeta?.path ?? "/legal",
    meta: legalc9RKhH9kWEMeta || {},
    alias: legalc9RKhH9kWEMeta?.alias || [],
    redirect: legalc9RKhH9kWEMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal.vue").then(m => m.default || m),
    children: [
  {
    name: legal_45noticewpnQ72bxJwMeta?.name ?? "legal-legal-notice___en",
    path: legal_45noticewpnQ72bxJwMeta?.path ?? "legal-notice",
    meta: legal_45noticewpnQ72bxJwMeta || {},
    alias: legal_45noticewpnQ72bxJwMeta?.alias || [],
    redirect: legal_45noticewpnQ72bxJwMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyibBrU0Dlr2Meta?.name ?? "legal-privacy-policy___en",
    path: privacy_45policyibBrU0Dlr2Meta?.path ?? "privacy-policy",
    meta: privacy_45policyibBrU0Dlr2Meta || {},
    alias: privacy_45policyibBrU0Dlr2Meta?.alias || [],
    redirect: privacy_45policyibBrU0Dlr2Meta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal/privacy-policy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: legalc9RKhH9kWEMeta?.name ?? "legal___es",
    path: legalc9RKhH9kWEMeta?.path ?? "/es/legal",
    meta: legalc9RKhH9kWEMeta || {},
    alias: legalc9RKhH9kWEMeta?.alias || [],
    redirect: legalc9RKhH9kWEMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal.vue").then(m => m.default || m),
    children: [
  {
    name: legal_45noticewpnQ72bxJwMeta?.name ?? "legal-legal-notice___es",
    path: legal_45noticewpnQ72bxJwMeta?.path ?? "aviso-legal",
    meta: legal_45noticewpnQ72bxJwMeta || {},
    alias: legal_45noticewpnQ72bxJwMeta?.alias || [],
    redirect: legal_45noticewpnQ72bxJwMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyibBrU0Dlr2Meta?.name ?? "legal-privacy-policy___es",
    path: privacy_45policyibBrU0Dlr2Meta?.path ?? "politica-de-privacidad",
    meta: privacy_45policyibBrU0Dlr2Meta || {},
    alias: privacy_45policyibBrU0Dlr2Meta?.alias || [],
    redirect: privacy_45policyibBrU0Dlr2Meta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal/privacy-policy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: legalc9RKhH9kWEMeta?.name ?? "legal___ca",
    path: legalc9RKhH9kWEMeta?.path ?? "/ca/legal",
    meta: legalc9RKhH9kWEMeta || {},
    alias: legalc9RKhH9kWEMeta?.alias || [],
    redirect: legalc9RKhH9kWEMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal.vue").then(m => m.default || m),
    children: [
  {
    name: legal_45noticewpnQ72bxJwMeta?.name ?? "legal-legal-notice___ca",
    path: legal_45noticewpnQ72bxJwMeta?.path ?? "avis-legal",
    meta: legal_45noticewpnQ72bxJwMeta || {},
    alias: legal_45noticewpnQ72bxJwMeta?.alias || [],
    redirect: legal_45noticewpnQ72bxJwMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyibBrU0Dlr2Meta?.name ?? "legal-privacy-policy___ca",
    path: privacy_45policyibBrU0Dlr2Meta?.path ?? "politica-de-privacitat",
    meta: privacy_45policyibBrU0Dlr2Meta || {},
    alias: privacy_45policyibBrU0Dlr2Meta?.alias || [],
    redirect: privacy_45policyibBrU0Dlr2Meta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/legal/privacy-policy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: librarynOTORHhtLFMeta?.name ?? "library___en",
    path: librarynOTORHhtLFMeta?.path ?? "/library",
    meta: librarynOTORHhtLFMeta || {},
    alias: librarynOTORHhtLFMeta?.alias || [],
    redirect: librarynOTORHhtLFMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/library.vue").then(m => m.default || m)
  },
  {
    name: librarynOTORHhtLFMeta?.name ?? "library___es",
    path: librarynOTORHhtLFMeta?.path ?? "/es/biblioteca",
    meta: librarynOTORHhtLFMeta || {},
    alias: librarynOTORHhtLFMeta?.alias || [],
    redirect: librarynOTORHhtLFMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/library.vue").then(m => m.default || m)
  },
  {
    name: librarynOTORHhtLFMeta?.name ?? "library___ca",
    path: librarynOTORHhtLFMeta?.path ?? "/ca/biblioteca",
    meta: librarynOTORHhtLFMeta || {},
    alias: librarynOTORHhtLFMeta?.alias || [],
    redirect: librarynOTORHhtLFMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/library.vue").then(m => m.default || m)
  },
  {
    name: missiongXqa2vWAPHMeta?.name ?? "mission___en",
    path: missiongXqa2vWAPHMeta?.path ?? "/mission",
    meta: missiongXqa2vWAPHMeta || {},
    alias: missiongXqa2vWAPHMeta?.alias || [],
    redirect: missiongXqa2vWAPHMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/mission.vue").then(m => m.default || m)
  },
  {
    name: missiongXqa2vWAPHMeta?.name ?? "mission___es",
    path: missiongXqa2vWAPHMeta?.path ?? "/es/mision",
    meta: missiongXqa2vWAPHMeta || {},
    alias: missiongXqa2vWAPHMeta?.alias || [],
    redirect: missiongXqa2vWAPHMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/mission.vue").then(m => m.default || m)
  },
  {
    name: missiongXqa2vWAPHMeta?.name ?? "mission___ca",
    path: missiongXqa2vWAPHMeta?.path ?? "/ca/missio",
    meta: missiongXqa2vWAPHMeta || {},
    alias: missiongXqa2vWAPHMeta?.alias || [],
    redirect: missiongXqa2vWAPHMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/mission.vue").then(m => m.default || m)
  },
  {
    name: our_45storyqlYjIRqEWyMeta?.name ?? "our-story___en",
    path: our_45storyqlYjIRqEWyMeta?.path ?? "/our-story",
    meta: our_45storyqlYjIRqEWyMeta || {},
    alias: our_45storyqlYjIRqEWyMeta?.alias || [],
    redirect: our_45storyqlYjIRqEWyMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/our-story.vue").then(m => m.default || m)
  },
  {
    name: our_45storyqlYjIRqEWyMeta?.name ?? "our-story___es",
    path: our_45storyqlYjIRqEWyMeta?.path ?? "/es/our-story",
    meta: our_45storyqlYjIRqEWyMeta || {},
    alias: our_45storyqlYjIRqEWyMeta?.alias || [],
    redirect: our_45storyqlYjIRqEWyMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/our-story.vue").then(m => m.default || m)
  },
  {
    name: our_45storyqlYjIRqEWyMeta?.name ?? "our-story___ca",
    path: our_45storyqlYjIRqEWyMeta?.path ?? "/ca/our-story",
    meta: our_45storyqlYjIRqEWyMeta || {},
    alias: our_45storyqlYjIRqEWyMeta?.alias || [],
    redirect: our_45storyqlYjIRqEWyMeta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/our-story.vue").then(m => m.default || m)
  },
  {
    name: wip5feJltVbL2Meta?.name ?? "wip___en",
    path: wip5feJltVbL2Meta?.path ?? "/wip",
    meta: wip5feJltVbL2Meta || {},
    alias: wip5feJltVbL2Meta?.alias || [],
    redirect: wip5feJltVbL2Meta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/wip.vue").then(m => m.default || m)
  },
  {
    name: wip5feJltVbL2Meta?.name ?? "wip___es",
    path: wip5feJltVbL2Meta?.path ?? "/es/wip",
    meta: wip5feJltVbL2Meta || {},
    alias: wip5feJltVbL2Meta?.alias || [],
    redirect: wip5feJltVbL2Meta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/wip.vue").then(m => m.default || m)
  },
  {
    name: wip5feJltVbL2Meta?.name ?? "wip___ca",
    path: wip5feJltVbL2Meta?.path ?? "/ca/wip",
    meta: wip5feJltVbL2Meta || {},
    alias: wip5feJltVbL2Meta?.alias || [],
    redirect: wip5feJltVbL2Meta?.redirect,
    component: () => import("/home/pdl/htdocs/puigdellivol.com/pages/wip.vue").then(m => m.default || m)
  },
  {
    name: puigdellivolo00hyJYhaiMeta?.name ?? undefined,
    path: puigdellivolo00hyJYhaiMeta?.path ?? "/sitemap.xml",
    meta: puigdellivolo00hyJYhaiMeta || {},
    alias: puigdellivolo00hyJYhaiMeta?.alias || [],
    redirect: puigdellivolo00hyJYhaiMeta?.redirect,
    component: puigdellivolo00hyJYhai
  }
]